import React, { useState, useRef, useEffect } from 'react';
import './detailedEstimate.css';
import logo from '../assets/login-logo.png';

const TrucksForm = () => {
  const [pickupLocation, setPickupLocation] = useState('');
  const [dropLocation, setDropLocation] = useState('');
  const [formData, setFormData] = useState({
    phoneNumber: '',
    name: '',
    email: '',
    goodsDescription: '',
    goodsWeight: '',
    goodsVolume: '',
    bookingStatus: 'pending',
    price: '',
    paymentStatus: 'pending',
    paymentMethod: 'cash',
    specialInstructions: '',
    userType: 'personal',
  });
  const [errors, setErrors] = useState({});
  const [currentSection, setCurrentSection] = useState(1);
  const [isPopupVisible, setIsPopupVisible] = useState(false); // Add this state
  const pickupRef = useRef(null);
  const dropRef = useRef(null);

  // Retrieve email from local storage on component mount
  useEffect(() => {
    const userEmail = localStorage.getItem('userEmail');  // Get stored email
    if (userEmail) {
      setFormData((prevData) => ({
        ...prevData,
        email: userEmail,
      }));
    }
  }, []);

  const handleUseCurrentLocation = async (setLocation) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;
        try {
          const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyDV3pZCvg1tIjv9CoXX2H3eaH46wEjifpM`);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          const currentAddress = data.results[0]?.formatted_address;
          if (currentAddress) {
            setLocation(currentAddress);
          }
        } catch (error) {
          console.error('Failed to fetch current location:', error);
        }
      });
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (value.trim() === '') {
      setErrors({ ...errors, [name]: `${name.replace(/([A-Z])/g, ' $1')} is required.` });
    } else {
      const newErrors = { ...errors };
      delete newErrors[name];
      setErrors(newErrors);
    }
  };

  const handleUserTypeChange = (type) => {
    setFormData({
      ...formData,
      userType: type,
    });
  };

  const validateSection = () => {
    let newErrors = {};
    if (currentSection === 1) {
      if (!pickupLocation.trim()) newErrors.pickupLocation = 'Pickup address is required.';
      if (!dropLocation.trim()) newErrors.dropLocation = 'Drop address is required.';
    } else if (currentSection === 2) {
      if (!formData.goodsDescription.trim()) newErrors.goodsDescription = 'Goods description is required.';
      if (!formData.goodsWeight.trim()) newErrors.goodsWeight = 'Goods weight is required.';
      if (!formData.price.trim()) newErrors.price = 'Good value is required.';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (validateSection()) {
      setCurrentSection(currentSection + 1);
    }
  };

  const handlePrevious = () => {
    setCurrentSection(currentSection - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateSection()) return;

    try {
      // Ensure all required fields are present and not empty
      if (!formData.email || !pickupLocation || !dropLocation) {
        alert('All required fields must be filled.');
        return;
      }

      const response = await fetch('https://linkfreightapi.vercel.app/api/booking', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: formData.email,  // Include email from local storage
          user_type: formData.userType,
          pickup_location: pickupLocation,
          dropoff_location: dropLocation,
          goods_description: formData.goodsDescription,
          goods_weight: parseFloat(formData.goodsWeight) || null,
          goods_volume: parseFloat(formData.price) || null,
          special_instructions: formData.specialInstructions,
        }),
      });

      if (response.ok) {
        const blob = await response.blob(); // Get the blob for the file
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'booking-details.xlsx'); // Set filename for download
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);

        // Display the popup
        setIsPopupVisible(true);
      } else {
        const errorData = await response.json();
        alert(`Error: ${errorData.error}`);
      }
    } catch (error) {
      console.error('Error creating booking:', error);
      alert('An error occurred. Please try again.');
    }
  };

  const closePopup = () => {
    setIsPopupVisible(false);
    resetForm();
  };

  const resetForm = () => {
    setPickupLocation('');
    setDropLocation('');
    setFormData({
      phoneNumber: '',
      name: '',
      email: '',
      goodsDescription: '',
      goodsWeight: '',
      goodsVolume: '',
      bookingStatus: 'pending',
      price: '',
      paymentStatus: 'pending',
      paymentMethod: 'cash',
      specialInstructions: '',
      userType: 'personal',
    });
    setCurrentSection(1);
    setErrors({});
  };

  return (
    <div className="trucks-form-container full-page">
      <div className="form-left">
        <img src={logo} className="login-logo" alt="logo" />
        <h1 className="form-title">Book Now</h1>
      </div>
      <div className="form-right">
        <form onSubmit={handleSubmit}>
          {currentSection === 1 && (
            <>
              <br /><br /><br /><br />
              <strong>
              <p>Please fill in the details, so we can provide you with the best of our services</p><br /><br /><br />
              </strong>
              <div className="form-group">
                <label htmlFor="pickupAddress">Pickup Address *</label>
                <div className="subform">
                  <input
                    type="text"
                    id="pickupAddress"
                    name="pickupAddress"
                    value={pickupLocation}
                    onChange={(e) => setPickupLocation(e.target.value)}
                    placeholder="Enter pickup address"
                    ref={pickupRef}
                    required
                  />
                  <button className="loc-button" type="button" onClick={() => handleUseCurrentLocation(setPickupLocation)}>
                    <i className="fa-solid fa-location-dot"></i>
                  </button>
                </div>
                {errors.pickupLocation && <span className="error">{errors.pickupLocation}</span>}
              </div>

              <div className="form-group">
                <label htmlFor="dropAddress">Drop Address *</label>
                <div className="subform">
                  <input
                    type="text"
                    id="dropAddress"
                    name="dropAddress"
                    value={dropLocation}
                    onChange={(e) => setDropLocation(e.target.value)}
                    placeholder="Enter drop address"
                    ref={dropRef}
                    required
                  />
                  <button className="loc-button" type="button" onClick={() => handleUseCurrentLocation(setDropLocation)}>
                    <i className="fa-solid fa-location-dot"></i>
                  </button>
                </div>
                {errors.dropLocation && <span className="error">{errors.dropLocation}</span>}
              </div><br /><br />
              <button type="button" className="enquiry-submit-btn" onClick={handleNext}>
                Next
              </button><br /><br /><br /><br /><br /><br />
            </>
          )}

          {currentSection === 2 && (
            <>
              <div className="form-group">
                <label htmlFor="goodsDescription">Goods Description *</label>
                <textarea
                  id="goodsDescription"
                  name="goodsDescription"
                  value={formData.goodsDescription}
                  onChange={handleChange}
                  placeholder="Enter goods description"
                  required
                />
                {errors.goodsDescription && <span className="error">{errors.goodsDescription}</span>}
              </div>

              <div className="form-group">
                <label htmlFor="goodsWeight">Goods Weight (kg) *</label>
                <input
                  type="number"
                  id="goodsWeight"
                  name="goodsWeight"
                  value={formData.goodsWeight}
                  onChange={handleChange}
                  placeholder="Enter goods weight"
                  required
                />
                {errors.goodsWeight && <span className="error">{errors.goodsWeight}</span>}
              </div>

              <div className="form-group">
                <label htmlFor="price">Good Value *</label>
                <input
                  type="number"
                  id="price"
                  name="price"
                  value={formData.price}
                  onChange={handleChange}
                  placeholder="Enter the Good value"
                  required
                />
                {errors.price && <span className="error">{errors.price}</span>}
              </div>

              <div className="form-group">
                <label htmlFor="specialInstructions">Special Instructions</label>
                <textarea
                  id="specialInstructions"
                  name="specialInstructions"
                  value={formData.specialInstructions}
                  onChange={handleChange}
                  placeholder="Enter special instructions"
                />
              </div>

              <div className="form-group">
                <label>What describes you best? *</label>
                <div className="user-type-options">
                  <button
                    style={{ width: 100 }}
                    id="typeuser"
                    type="button"
                    className={formData.userType === 'personal' ? 'active' : ''}
                    onClick={() => handleUserTypeChange('personal')}
                  >
                    Personal User
                  </button>
                  <button
                    style={{ width: 100 }}
                    id="typeuser"
                    type="button"
                    className={formData.userType === 'business' ? 'active' : ''}
                    onClick={() => handleUserTypeChange('business')}
                  >
                    Business User
                  </button>
                </div>
              </div>
              <br /><br />
              <div className="form-group-buttons">
                <button type="button" className="enquiry-submit-btn" onClick={handlePrevious}>
                  Previous
                </button>
                <button type="submit" className="enquiry-submit-btn">
                  Get Estimate
                </button>
              </div>
            </>
          )}
        </form>
      </div>

      {/* Popup Modal */}
      {isPopupVisible && (
        <div className="detailed-popup-overlay">
          <div className="detailed-popup-content">
            <button className="detailed-close-popup-btn" onClick={closePopup}>X</button>
            <svg className="detailed-popup-checkmark" viewBox="0 0 52 52">
              <circle className="detailed-checkmark-circle" cx="26" cy="26" r="25" fill="none"/>
              <path className="detailed-checkmark-check" fill="none" d="M14 27l10 10 15-15"/>
            </svg>
            <h3>Booking successful! We will get back to you soon!</h3><br /><br />

            {/* New Pickup and Drop Containers */}
            <div className="detailed-address-container" style={{ animationDelay: '0.5s' }}>
              <div className="detailed-pickup-container">
                <strong>Pickup Address:</strong>
                <p>{pickupLocation}</p>
              </div>
              <div className="detailed-drop-container">
                <strong>Drop Address:</strong>
                <p>{dropLocation}</p>
              </div>
            </div>

            {/* Split Table Layout */}
            <div className="detailed-popup-table-container">
              <div className="detailed-popup-table-centered">
                <table className="detailed-popup-form-data-table">
                  <tbody>
                    <tr><td><strong>Goods Description:</strong></td><td>{formData.goodsDescription}</td></tr>
                    <tr><td><strong>Goods Weight:</strong></td><td>{formData.goodsWeight} kg</td></tr>
                    <tr><td><strong>Goods Volume:</strong></td><td>{formData.price} m³</td></tr>
                  </tbody>
                </table>
              </div>
            </div><br /><br />

            {/* Centered Comments Section */}
            <div className="detailed-popup-comments-container">
              <strong>Special Instructions:</strong><br />
              <br />
              <p>{formData.specialInstructions}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TrucksForm;
