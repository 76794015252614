// src/Faq.js
import React, { useState } from 'react';
import './faqs.css';

const Faq = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const questions = [
        {
            question: "What services do you offer?",
            answer: "We offer a range of truck transport services including freight management, local deliveries, and specialized transport solutions."
        },
        {
            question: "How can I book a truck?",
            answer: "You can book a truck through our website or mobile app. Simply enter your details, select the type of service, and confirm your booking."
        },
        {
            question: "What areas do you serve?",
            answer: "We primarily serve Bangalore city, but we also offer transport services to nearby areas upon request."
        },
        {
            question: "What are your rates?",
            answer: "Our rates vary depending on the type of service and distance. You can get an estimate through our booking platform or contact us for more details."
        },
        {
            question: "How can I track my shipment?",
            answer: "Once your shipment is on the way, you will receive a tracking number that you can use to monitor its progress on our website or app."
        },
        {
            question: "What should I do in case of an accident?",
            answer: "In case of an accident, please contact our emergency support team immediately. We will assist you in alerting the necessary services and provide support as needed."
        },
    ];

    const toggleAnswer = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className="faq-container">
            <h2 className='title'>FAQs</h2>
            {questions.map((item, index) => (
                <div key={index} className="faq-item">
                    <div className="faq-question" onClick={() => toggleAnswer(index)}>
                        {item.question}
                        <span className={`faq-icon ${activeIndex === index ? 'open' : ''}`}>&#9660;</span>
                    </div>
                    {activeIndex === index && (
                        <div className="faq-answer">
                            {item.answer}
                        </div>
                    )}
                </div>
            ))}
            <br/><br/><br />
        </div>
    );
}; 

export default Faq;
