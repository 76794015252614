import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Link } from 'react-scroll';
import './navbar.css';
import backgroundImage from '../assets/background.jpeg';
import logo from '../assets/logo.png';
import BookNow from '../bookNow/booknow';

const Navbar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userFullName, setUserFullName] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        } else {
          entry.target.classList.remove('visible');
        }
      });
    });

    const words = document.querySelectorAll('.word');
    words.forEach(word => observer.observe(word));

    return () => {
      words.forEach(word => observer.unobserve(word));
    };
  }, []);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setIsLoggedIn(true);
      setUserFullName(user.fullname);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('user');
    setIsLoggedIn(false);
    setUserFullName('');
    window.location.href = '/';
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleBookNowClick = () => {
    if (isLoggedIn) {
      navigate('/truck-form');
    } else {
      setShowPopup(true);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleMyBookingsClick = () => {
    navigate('/my-bookings');
    setIsDropdownOpen(false);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <nav className="navbar" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="navbar-content">
        <div className="navbar-brand">
          <a href="#"><img src={logo} className='logo' alt="Logo" /></a>
          <p>LINKFREIGHT</p>
        </div>
        <div className="navbar-links">
          <ul>
            <li>
              <Link to="home" smooth={true} duration={500}>HOME</Link>
            </li>
            <li>
              <Link to="about" smooth={true} duration={500}>ABOUT</Link>
            </li>
            <li>
              <Link to="services" smooth={true} duration={500}>SERVICES</Link>
            </li>
            <li>
              <Link to="contact" smooth={true} duration={500}>CONTACT</Link>
            </li>
            <li>
              <RouterLink to="/get-enquiry">GET ENQUIRY</RouterLink> 
            </li>
            {isLoggedIn ? (
              <li className="profile-dropdown">
                <div className="profile-icon" onClick={toggleDropdown}>
                  <i className="fas fa-user"></i>
                </div>
                {isDropdownOpen && (
                  <div className="dropdown-content">
                    <p>{userFullName}</p>
                    <button id="dropdown" onClick={handleMyBookingsClick}>
                      <b><strong>My Bookings</strong></b>
                    </button>
                    <button onClick={handleLogout}>Logout</button>
                  </div>
                )}
              </li>
            ) : (
              <li>
                <RouterLink to="/login">LOGIN <i className="fas fa-arrow-right"></i></RouterLink>
              </li>
            )}
          </ul>
        </div>
        <div className="menu-icon" onClick={toggleSidebar}>
          <i className="fas fa-bars"></i>
        </div>
      </div>
      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <div className="close-btn" onClick={toggleSidebar}>
          <i className="fas fa-times"></i>
        </div>
        <ul>
        <b><p style={{color:"white"}}>{userFullName}</p></b><br /><br />
          <li>
            <Link to="home" smooth={true} duration={500} onClick={toggleSidebar}>HOME</Link>
          </li>
          <li>
            <Link to="about" smooth={true} duration={500} onClick={toggleSidebar}>ABOUT</Link>
          </li>
          <li>
            <Link to="services" smooth={true} duration={500} onClick={toggleSidebar}>SERVICES</Link>
          </li>
          <li>
            <Link to="contact" smooth={true} duration={500} onClick={toggleSidebar}>CONTACT</Link>
          </li>
          <li>
            <RouterLink to="/get-enquiry" onClick={toggleSidebar}>GET ENQUIRY</RouterLink> 
          </li>
          {isLoggedIn ? (
            <>
              <li onClick={toggleSidebar}>
                <RouterLink to="/my-bookings">MY BOOKINGS</RouterLink>
              </li>
              <li className='Mlogout' style={{color:"white"}} onClick={() => { handleLogout(); toggleSidebar(); }}>
                <p>LOGOUT</p>
              </li>
            </>
          ) : (
            <li onClick={toggleSidebar}>
              <RouterLink to="/login">LOGIN <i className="fas fa-arrow-right"></i></RouterLink>
            </li>
          )}
        </ul>
      </div>
      <div className="quote-box">
        <p className="main-quote">
          <span className="word">Transporting</span>
          <span className="word">Trust & Reliability</span>
        </p>
        <p className="sub-quote">
          Ensuring Your Goods Reach Their Destination <span className="span">Safely</span> and <span className="span">On Time</span>
        </p>
      </div>
      <div className="button-container">
        <button className="button" onClick={handleBookNowClick}>BOOK NOW</button>
      </div>
      {showPopup && !isLoggedIn && (
        <div className="modal">
          <BookNow onClose={handleClosePopup} onEstimate={() => { }} isLoggedIn={isLoggedIn} />
        </div>
      )}
    </nav>
  );
};

export default Navbar;
