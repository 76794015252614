import React, { useState } from 'react';
import './contact.css';

const Contactus = () => {
    const [fullname, setFullname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false); // New loading state

    const validateForm = () => {
        const newErrors = {};

        // Email validation regex pattern
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        
        // Phone number validation regex (for 10-digit phone numbers)
        const phonePattern = /^\d{10}$/;

        if (!email) {
            newErrors.email = 'Email is required.';
        } else if (!emailPattern.test(email)) {
            newErrors.email = 'Please enter a valid email address.';
        }

        if (!phone) {
            newErrors.phone = 'Phone number is required.';
        } else if (!phonePattern.test(phone)) {
            newErrors.phone = 'Please enter a valid 10-digit phone number.';
        }

        setErrors(newErrors);

        // Return true if there are no errors
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate form before submission
        if (!validateForm()) return;

        const contactData = {
            fullname,
            email,
            phone,
            message,
        };

        setIsLoading(true); // Start loading

        try {
            const response = await fetch('https://linkfreightapi.vercel.app/api/contactUs', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(contactData),
            });

            if (response.ok) {
                setStatus('Thank you for contacting,We will get back to you very soon with the solution for you.');
                setFullname('');
                setEmail('');
                setPhone('');
                setMessage('');
                setErrors({});
            } else {
                setStatus('Failed to send your message. Please try again.');
            }
        } catch (error) {
            setStatus('An error occurred. Please try again.');
        } finally {
            setIsLoading(false); // Stop loading
        }
    };

    return (
        <section className='contact'>
            <div className='content'>
                <h2 className='title'>CONTACT <span className='span-title'>US</span></h2>
            </div><br /><br /><br /><br />
            <div className='container'>
                <div className='contactWrapper'>
                    <div className='contactInfo'>
                        <div className='box'>
                            <div className='icon'><b><i className="fa-solid fa-location-dot"></i></b></div>
                            <div className='text'>
                                <h3>Address</h3>
                                <p className='address'>405544 Sugar Camp Road, <br />Owatonna, Minnesota, <br />22545521</p>
                            </div>
                        </div>
                        <div className='box'>
                            <div className='icon'><b><i className="fa-solid fa-phone"></i></b></div>
                            <div className='text'>
                                <h3>Phone</h3>
                                <p>0000000000</p>
                            </div>
                        </div>
                        <div className='box'>
                            <div className='icon'><b><i className="fa-solid fa-envelope"></i></b></div>
                            <div className='text'>
                                <h3>Email</h3>
                                <p>samplemail@gmail.com</p>
                            </div>
                        </div>
                        <h2 className='txt'>Connect with us</h2>
                        <ul className='sci'>
                            <li><a href="#"><i className="fa-brands fa-facebook"></i></a></li>
                            <li><a href="#"><i className="fa-brands fa-twitter"></i></a></li>
                            <li><a href="#"><i className="fa-brands fa-instagram"></i></a></li>
                            <li><a href="#"><i className="fa-brands fa-linkedin"></i></a></li>
                        </ul>
                    </div>
                    <div className='contactForm'>
                        <form onSubmit={handleSubmit}>
                            <div className='inputBox'>
                                <input
                                    type="text"
                                    name="fullname"
                                    value={fullname}
                                    onChange={(e) => setFullname(e.target.value)}
                                    required="required"
                                />
                                <span>Full Name</span>
                            </div>
                            <div className='inputBox'>
                                <input
                                    type="text"
                                    name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required="required"
                                />
                                <span>Email</span>
                                {errors.email && <span className="error-message">{errors.email}</span>}
                            </div>
                            <div className='inputBox'>
                                <input
                                    type="text"
                                    name="phone"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    required="required"
                                />
                                <span>Phone</span>
                                {errors.phone && <span className="error-message">{errors.phone}</span>}
                            </div>
                            <div className='inputBox'>
                                <textarea
                                    name="message"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    required="required"
                                ></textarea>
                                <span>Type your Message</span>
                            </div>
                            <div className='inputBox'>
                                <input 
                                    type="submit" 
                                    value={isLoading ? 'Submitting...' : 'Send'} 
                                    disabled={isLoading} 
                                />
                            </div>
                        </form>
                        {status && <p className="status-message">{status}</p>}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contactus;
