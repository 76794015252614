import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './booknow.css';

const BookNow = ({ onClose, onEstimate, isLoggedIn }) => {
  const [pickup, setPickup] = useState('');
  const [destination, setDestination] = useState('');
  const [estimateAmount, setEstimateAmount] = useState('');
  const [pickupError, setPickupError] = useState(false);
  const [destinationError, setDestinationError] = useState(false);
  const navigate = useNavigate();

  const handleEstimateClick = () => {
    // Reset error states
    setPickupError(false);
    setDestinationError(false);

    // Check if the fields are empty
    if (!pickup) setPickupError(true);
    if (!destination) setDestinationError(true);

    // If any field is empty, prevent submission
    if (!pickup || !destination) {
      return;
    }

    const estimateAmount = "Estimated Amount: $50"; 
    setEstimateAmount(estimateAmount);
    onEstimate(estimateAmount);

    if (isLoggedIn) {
      navigate('/truck-form'); // Navigate to /truck-form if logged in
    } else {
      navigate('/estimate-popup', { state: { estimateAmount } }); // Navigate to /login if not logged in
    }
  };

  const handlePickupChange = (e) => {
    setPickup(e.target.value);
    if (e.target.value) setPickupError(false); // Clear error when user starts typing
  };

  const handleDestinationChange = (e) => {
    setDestination(e.target.value);
    if (e.target.value) setDestinationError(false); // Clear error when user starts typing
  };

  return (
    <div className="booknow-container">
      <h2 className="title">Book Now</h2>
      <div className="form-group-horizontal">
        <div className="form-group">
          <div className="input-container">
            <input
              type="text"
              id="pickup-location"
              className="form-control"
              placeholder="Enter pickup location"
              value={pickup}
              onChange={handlePickupChange}
              required
            />
            <i className="location-icon fa fa-location-arrow"></i>
          </div>
          {pickupError && <p className="error-message">This field is required</p>} {/* Error message */}
        </div>
        <div className="form-group">
          <div className="input-container">
            <input
              type="text"
              id="destination-location"
              className="form-control"
              placeholder="Enter destination location"
              value={destination}
              onChange={handleDestinationChange}
              required
            />
            <i className="location-icon fa fa-location-arrow"></i>
          </div>
          {destinationError && <p className="error-message">This field is required</p>} {/* Error message */}
        </div>
      </div>
      <button className="estimate-button" onClick={handleEstimateClick}>Get Estimate</button>
      {/* {estimateAmount && <p className="estimate-result">{estimateAmount}</p>} */}
      <button className="close-button" onClick={onClose}>X</button>
    </div>
  );
};

export default BookNow;
